import { type TSocialMedia } from '@shared/ui'
import { useMemo } from 'react'

import useData from './query'

export default () => {
  const {
    site: {
      siteMetadata: {
        externalUrls: { discord: discordUrl, x: xUrl },
      },
    },
  } = useData()

  const links = useMemo<{ ariaLabel: TSocialMedia; to: string }[]>(
    () => [
      { ariaLabel: 'Discord', to: discordUrl },
      { ariaLabel: 'X', to: xUrl },
    ],
    [discordUrl, xUrl]
  )

  return links
}
