import { LegalNotice, Section, Theme } from '@shared/ui'
import React, { FC } from 'react'

import styles from './styles.module.css'

const LegalSection: FC = () => (
  <Section className={styles.shortSection} theme={Theme.DARK}>
    <LegalNotice />
  </Section>
)

export default LegalSection
